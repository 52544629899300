// eslint-disable-next-line import/prefer-default-export
export const terminalHero = {
  title: "Taking payments is only the beginning",
  subtext:
    "Run and grow your business with SpotOn Terminal, the all-in-one smart device for payments, marketing, and customer insights.",
  heroImg: "terminal-hero.png",
  PrimaryCta: {
    ctaTitle: "Learn more",
    ctaTarget: "/products/terminal/demo",
  },
  fatTitle: "Terminal",
  circleBg: false,
};

export const terminalTrustedNumbers = [
  {
    title: "300 million",
    subtext: "Marketing campaigns",
  },
  {
    title: "5+ million",
    subtext: "Loyalty redemptions",
  },
  {
    title: "2,000+",
    subtext: "New businesses each month",
  },
  {
    title: "7+ million",
    subtext: "Consumers",
  },
];

export const terminalWhiteGLove = {
  mainTitle: "We are there for you",
  title: "Expert support. \nFrom real people",
  content:
    "Get local service and 24/7 support from real people. We'll help optimize SpotOn Terminal to meet your needs, then show you how to use it like a pro. We’ll also set you up with a cloud-based Business Dashboard so you can monitor your business in real-time, take online payments, run marketing campaigns, and get customer insights—anywhere your business takes you.",
  ctaInfoPrimary: {
    ctaTitle: "Learn more",
    ctaTarget: "/products/terminal/demo",
  },
};

export const cutWires = {
  sectionTitle: "Enterprise efficiency for small businesses",
  sectionNavId: "pos-terminal",
  featureBlocks: [
    {
      blockTitle: "Cut the wires. Connect with customers. Get paid",
      blockSubTitle:
        "You deserve tools that make it easier to run your business. SpotOn Terminal does just that. Compact, powerful, and packed with features, it lets you take payments wherever you want, drive repeat visits, and access critical data to make smarter decisions.",
      blockList: [
        "Customer data capture",
        "Built-in marketing tools",
        "Next-day funding & transparent pricing",
      ],
      blockImg: "phone-auth.png",
      forceReverse: false,
      ctaInfo: {
        ctaTitle: "Learn more",
        ctaTarget: "/products/terminal/demo",
      },
    },
    {
      blockTitle: "Accept payments your way",
      blockSubTitle:
        "Cash. Cards. Contactless payments. Do business your way—and do it fast—thanks to the intuitive features packed into SpotOn Terminal.",
      blockList: [
        "Apple Pay, Google Pay, and NFC enabled",
        "Wi-Fi and 4G connectivity",
        "Cash discount and surcharge ready",
        "Real-time data and sales reporting",
      ],
      blockImg: "pos-payment.png",
      forceReverse: false,
      ctaInfo: {
        ctaTitle: "Learn more",
        ctaTarget: "/products/terminal/demo",
      },
    },
    {
      blockTitle: "Market, manage, invoice, and report",
      blockSubTitle:
        "SpotOn Terminal includes powerful tools to help you grow without constraint. Send marketing emails and deals, manage your online reviews, send invoices with our virtual terminal, view sales reports, and even launch a digital loyalty program to drive repeat visits.",
      blockImg: "laptop+phone.png",
      forceReverse: false,
      ctaInfo: {
        ctaTitle: "Learn more",
        ctaTarget: "/products/terminal/demo",
      },
    },
  ],
};

export const unifiedTerminalLarge = [];

export const terminalTypesCar = {
  title: "The payment terminal built for businesses like yours",
  subtext:
    "You deserve a payment terminal that can keep up with everything you do. SpotOn Terminal is compact, flexible, and simple to use, making your job easier.",
  slides: [
    {
      bg: "auto.png",
      cardTitle: "Automotive",
      cardContent:
        "Oil Change Shops, Auto Parts, Auto Body, Repair, Tire Shops, Tint Shops",
      icon: "automotive.png",
      linkTo: "/automotive",
    },
    {
      bg: "beauty.png",
      cardTitle: "Health & beauty",
      cardContent:
        "Nail/Hair Salons, Tanning Salons, Barbers, Dentists, Veterinarian",
      icon: "health-and-beauty.png",
      linkTo: "/health-and-beauty",
    },
    {
      bg: "professional.png",
      cardTitle: "Professional Services",
      cardContent:
        "Lawyer, Accountant, Tow Truck, Architect, Plumber, Appraiser",
      icon: "prof-services.png",
      linkTo: "/professional-services",
    },
  ],
};

export const terminalSuccess = {
  title: "success stories",
  subTitle: "SpotOn client",
};

export const terminalSlides = [
  {
    image: "shakesspear.png",
    companyName: "Pinecrest Bakery",
    companyIs: "Cafe & Bakery chain",
    companyLogo: "sp-logo.png",
    address: "South Miami",
    owner: "Efrain Valdes",
    website: "www.pinecrestbakery.com",
    description:
      "When Efrain Valdez and his family moved from Little Havana to the Pinecrest neighborhood of South Miami, they were surprised to find there weren’t many options for home-style…\n",
    url: "/page-2",
    ctaPrimary: "Read The Story",
    info: [
      {
        numbers: "$67,000",
        numberType: "annual loyalty revenue",
      },
      {
        numbers: "20",
        numberType: "Locations",
      },
    ],
  },
  {
    image: "shakesspear.png",
    companyName: "Cacio",
    companyIs: "Casual dining restaurant",
    companyLogo: "sp-logo.png",
    address: " Sacramento, CA",
    owner: "Katie Kinner & Jonathan Kerksieck",
    website: "caciosacramento.com",
    description:
      "Cacio is an Italian-inspired, dine-in restaurant located in Sacramento, California, that's been open since 2018. Like so many restaurant owners, co-owner Katie Kinner was faced with a daunting challenge when the pandemic forced her to close for on-premise dining...",
    url: "page-2",
    ctaPrimary: "Read The Story",
    info: [
      {
        numbers: "100%",
        numberType:
          "Transition to take-out with no reduction in orders per month",
      },
      {
        numbers: "40%",
        numberType: "Saved on additional staffing costs",
      },
    ],
  },
];

export const terminalVideo = {
  title: "Run your business from the palm of your hand",
  videoBtn: "Watch video",
  posterWrapperClass: "relative",
  posterImg: "terminal-poster.png",
  videoUrlId: "3oWbgvaZcv0",
};

export const terminalSeloutionsSection = {
  title: "Integrates seamlessly with other tools for growth",
  subText: `Instead of a tangled web of costly systems that are a nightmare to manage, SpotOn offers a simple, integrated solution with the tools you need to run and grow your business.`,
  SecondaryCta: {
    ctaTitle: "View all",
    ctaTarget: "/page-2",
  },
};

export const terminalTestimonials = {
  title: "What other business owners are saying",
  reviews: [
    {
      quote:
        "From the initial consultation, to ordering, to implementation and integration, the entire process of on-boarding the SpotOn POS System has been seamless and has exceeded our expectations.",
      reviewDate: "Mar 9, 2021",
      reviewer: "Pete M.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn continues to be an invaluable technology partner that listens well and proactively presents innovative solutions to drive our business forward.",
      reviewDate: "Feb 9, 2021",
      reviewer: "Ben P.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn has given me the data access I need to manage my business from a macro as well as micro perspective. The software is easy to access and utilize.",
      reviewDate: "Dec 5, 2020",
      reviewer: "Pamela H.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "Every issue and question has been answered or addressed within hours, if not minutes.I can't say enough good about this company and software.",
      reviewDate: "Feb 24, 2021",
      reviewer: "Brandy R.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "From back office, digital marketing, built our website to take orders off of. The programming is great to allow you to do more tasks with better trained staff.",
      reviewDate: "Dec 1, 2020",
      reviewer: "Marcus O.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
  ],
};

export const terminalIntegrations = {
  title: "Work with the software you know",
  subtext:
    "Sync all kind of third party applications and service with SpotOn and automate your revenue growth, retention and marketing processes.",
  integrationsContent: [
    {
      title: "Compatible with SpotOn Register.",
      subtext:
        "Run and grow your business more efficiently than ever before with our cutting-edge point-of-sale solution.",
      contentImg: "register.png",
      ImgClassName: "register",
      btnInfo: {
        ctaTitle: "How it Works",
        ctaTarget: "/page-2",
      },
    },
    {
      title: "Out of the box Stripe integration.",
      subtext:
        "Accept payments and move money globally with Stripe’s powerful APIs and software solutions",
      contentImg: "phone.png",
      ImgClassName: "phone",
      btnInfo: {
        ctaTitle: "Read More",
        ctaTarget: "/page-2",
      },
    },
  ],
};

export const terminalCta = {
  title: "Talk to a growth specialist.",
  ctaImg: "cta-people.png",
  ctaInfo: {
    ctaTitle: "Learn more",
    ctaTarget: "/products/terminal/demo",
  },
};

export const terminalFAQs = {
  title: "Frequently Asked Questions",
  ctaInfo: {
    ctaTitle: "View more",
    ctaTarget: "/page-2",
  },
  list: [
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
  ],
};

export const terminalSolutionSlides = [
  {
    title: "Appointments",
    img: "Appointments1.png",
    link: "/products/appointments",
  },
  {
    title: "Register",
    link: "/products/register",
    img: "Register.png",
  },
  {
    title: "Payments",
    link: "/products/payments",
    img: "Payments.png",
  },
  {
    title: "Mobile",
    link: "/products/mobile",
    img: "Mobile.png",
  },
  {
    title: "Virtual Terminal",
    link: "/products/virtual-terminal",
    img: "Virtual Terminal.png",
  },
  // {
  //   title: 'Online Ordering',
  //   link: '/products/online-ordering',
  //   img: 'Online ordering.png',
  // },
  {
    title: "Capital",
    link: "/products/capital",
    img: "Capital.png",
  },
  // {
  //   title: 'Payroll',
  //   link: '/products/payroll',
  //   img: 'Payroll.png',
  // },
  // {
  //   title: 'Delivery',
  //   link: '/products/delivery',
  //   img: 'Delivery.png',
  // },
  {
    title: "Loyalty",
    link: "/products/loyalty",
    img: "Loyalty.png",
  },
  {
    title: "Review Management",
    link: "/products/review-management",
    img: "Review management.png",
  },
  {
    title: "Marketing",
    link: "/products/marketing",
    img: "Marketing.png",
  },
  // {
  //   title: 'Reserve',
  //   link: '/products/reserve',
  //   img: 'Reserve.png',
  // },
  {
    title: "Website & E-commerce",
    link: "/products/e-commerce",
    img: "E-commerce & websites.png",
  },
  {
    title: "Terminal",
    link: "/products/terminal",
    img: "Terminal.png",
  },
  {
    title: "Reporting",
    link: "/products/reporting",
    img: "Reporting.png",
  },
  // {
  //   title: 'Restaurant',
  //   link: '/restaurants',
  //   img: 'Restaurant.png',
  // },
];

import {
  any,
  arrayOf,
  bool,
  shape,
  string,
  oneOfType,
  oneOf,
  number,
} from "prop-types";

export default {
  data: arrayOf(
    shape({
      itemType: string.isRequired,
      itemSubType: string,
      label: string.isRequired,
      name: string.isRequired,
      rules: arrayOf(
        shape({
          required: bool,
          message: string,
          // eslint-disable-next-line react/forbid-prop-types
          pattern: any,
        })
      ),
      placeholder: string,
      options: any,
      defaultValue: oneOfType([string, bool, number]),
    })
  ).isRequired,
};

import React from "react";
import {
  any,
  arrayOf,
  bool,
  number,
  oneOfType,
  shape,
  string,
} from "prop-types";
import { Form, Input as AntInput } from "antd";

const Input = ({ itemSubType, label, name, rules, placeholder, aria }) => {
  const customAtt = { "aria-description": aria };
  return (
    <Form.Item label={label} name={name} rules={rules || []}>
      <AntInput
        aria-required="true"
        size="large"
        {...customAtt}
        type={itemSubType}
        placeholder={placeholder}
      />
    </Form.Item>
  );
};

Input.propTypes = {
  itemSubType: string.isRequired,
  label: string.isRequired,
  name: string.isRequired,
  rules: arrayOf(
    shape({
      required: bool,
      message: string,
      // eslint-disable-next-line react/forbid-prop-types
      pattern: any,
    })
  ),
  placeholder: string.isRequired,
  aria: string,
};

Input.defaultProps = {
  rules: [],
  aria: "",
};

export default Input;

import { doc, setDoc } from "firebase/firestore";
import { db, signAnonymously } from "./firestore";
import uid from "../uid";
import { getSubmittedOnAndConversionId } from "../form-utils";

export const DEMO = "SpotOn_Demo_Forms";
export const GATED_ASSETS = "SpotOn_Gated_Assets";
export const POINTS_OF_PROFIT = "SpotOn_Points_of_Profit";
export const ENTERPRISE_PARTNER = "SpotOn_Demo_Enterprise_Partners";
export const VIETNAMESE_FORM = "SpotOn_Viet_Lp";
export const RESERVE = "SpotOn_Reserve_Leads";
export const ENTERPRISE_DEMO = "SpotOn_Enterprise_Demo";
export const EMPLOYEE_REFERRALS = "SpotOn_Employee_Referrals";
export const HS_REFERRALS = "SpotOn_HS_Referrals";
export const HOSPITALITY_LPS = "SpotOn_Hospitality_LPs";
export const WEBINARS_POINTS_OF_PROFIT = "SpotOn_Webinars_PointsOfProfit";
export const OFFERS_LP = "SpotOn_Offers_LP";
export const DIRECT_MAIL_LP = "SpotOn-Direct-Mail";
export const SPOTON_TEAMWORK = "SpotOn_Teamwork";
export const ONLINE_ORDERING = "SpotOn_Online_Ordering";
export const BECOME_A_PARTNER = "SpotOn_Integration_Partners";
export const MERCHANT_REFERRAL = "SpotOn_Merchant_Referrals";
export const SAASQUATCH_MERCHANT_REFERRAL =
  "SpotOn_Saasquatch_Merchant_Referral";
export const CAPITAL = "SpotOn_Capital";

export const submitForm = async (data = {}, collection = DEMO) => {
  try {
    await signAnonymously().then(() => {
      const id = uid();
      return setDoc(
        doc(db, collection, id),
        { ...data, ...getSubmittedOnAndConversionId() },
        {
          merge: true,
        }
      );
    });
    return true;
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const submitEmployeeReferralForm = async (data = {}) => {
  if (!data.referrer_email) {
    return false;
  }

  try {
    await signAnonymously().then(() => {
      const id = uid();
      return setDoc(
        doc(db, EMPLOYEE_REFERRALS, id),
        { ...data, ...getSubmittedOnAndConversionId() },
        {
          merge: true,
        }
      );
    });
    return true;
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const submitHsReferralForm = async (data = {}) => {
  if (!data.referrer_email) {
    return false;
  }

  try {
    await signAnonymously().then(() => {
      const id = uid();
      return setDoc(
        doc(db, HS_REFERRALS, id),
        { ...data, ...getSubmittedOnAndConversionId() },
        {
          merge: true,
        }
      );
    });
    return true;
  } catch (err) {
    console.log(err);
    return false;
  }
};

import React from "react";
import { viewports } from "../../style-vars";

const { smOrSmaller, mdOrSmaller } = viewports;

const TrustedNumbersStyles = () => (
  <style jsx global>{`
    .trusted-numbers {
      position: relative;
      padding: 0 100px;
    }
    @media ${smOrSmaller} {
      .trusted-numbers {
        padding: 0 32px;
        margin-top: 40px;
      }
    }
    .trusted-numbers h2 {
      margin-bottom: 32px !important;
    }
    .trusted-numbers__block {
      border-top: solid 3px rgba(23, 105, 255, 0.1);
    }
    @media ${mdOrSmaller} {
      .trusted-numbers__block {
        border-top: none;
        border-bottom: solid 3px rgba(23, 105, 255, 0.1);
      }
    }
    .trusted-numbers__block h3 {
      color: #000 !important;
      margin-top: 20px;
      margin-bottom: 12px;
    }
    .trusted-numbers__block .special {
      color: #000 !important;
      margin-top: 20px;
      margin-bottom: 12px !important;
    }
    @media ${smOrSmaller} {
      .trusted-numbers__block h3 {
        margin-top: 0;
      }
      .trusted-numbers__block .special {
        color: #000 !important;
        margin-top: 0;
        margin-bottom: 12px !important;
      }
    }
    .trusted-numbers__block p {
      color: #748093;
      font-size: 16px;
    }
    @media ${smOrSmaller} {
      .no-bottom {
        border-bottom: none !important;
      }
    }
    .numbers-spacing {
      padding: 32px 160px;
    }
    @media screen and (max-width: 1280px) {
      .numbers-spacing {
        padding: 32px 0px;
      }
    }
    @media ${smOrSmaller} {
      .numbers-spacing {
        padding: 32px 0;
      }
    }
  `}</style>
);

export default TrustedNumbersStyles;

import React from "react";
import { string, any } from "prop-types";
import { Form, Checkbox as AntCheckbox } from "antd";

const CheckboxGroup = ({ label, options }) => {
  return (
    <>
      <div>{label}</div>

      <div className="flex flex-wrap gap-x-6 checkbox-group-wrapper mt-2">
        {options.map((opt) => (
          <Form.Item key={opt.value} name={opt.value} valuePropName="checked">
            <AntCheckbox key={opt.label} value={opt.value}>
              {opt.label}
            </AntCheckbox>
          </Form.Item>
        ))}
      </div>

      <style jsx global>{`
        .checkbox-group-wrapper .ant-checkbox-wrapper {
          color: #646e8a;
        }
      `}</style>
    </>
  );
};

CheckboxGroup.propTypes = {
  label: string.isRequired,
  // name: string.isRequired,
  // eslint-disable-next-line react/require-default-props
  options: any,
};

CheckboxGroup.defaultProps = {};

export default CheckboxGroup;

import React from 'react';
import { string, bool } from 'prop-types';
import { Form, Checkbox as AntCheckbox } from 'antd';

const Checkbox = ({ label, name, defaultValue }) => {
  return (
    <Form.Item name={name} valuePropName="checked">
      <AntCheckbox defaultChecked={defaultValue}>{label}</AntCheckbox>
    </Form.Item>
  );
};

Checkbox.propTypes = {
  label: string.isRequired,
  name: string.isRequired,
  defaultValue: bool,
};

Checkbox.defaultProps = {
  defaultValue: false,
};

export default Checkbox;

import React, { useState, useEffect } from "react";
import { bool, string } from "prop-types";
import Layout from "../components/Layout/layout";
import SEO from "../components/seo";
import LeadFormTeamwork from "../components/Forms/LeadFormTeamwork";
import Image from "../components/image";
import TrustedNumnbers from "../components/TrustedNumbers/TrustedNumbers";
import PartnerLogos from "../components/PartnerLogos/PartnerLogos";
import TestmonialReviews from "../components/TestimonialReviews/TestmonialReviews";
import { demoTestimonials } from "../data/restaurant-data";
import { terminalTrustedNumbers } from "../data/terminal-landing-data";
import data from "../data/forms/lead-form-with-product-interest";
import { formItemVisibilityEventName } from "../constants";
import { extractDefaultValues } from "../utils/form-utils";

// import '../styles/demo.less';
import ogImage from "../images/global_assets/og-image.png";

export default function DemoWithProductsInterestCheck({
  useFormValueAsDataLayerEventName,
  formValueForDataLayerEventName,
  dataLayerEventNameAppendix,
}) {
  const [displayForm, setDisplayForm] = useState(false);
  const [formData, setFormData] = useState(data || []);
  const [initialValues, setInitialValues] = useState({});

  useEffect(() => {
    if (typeof window !== "undefined") {
      const newFormData = formData.map((item) => {
        return item;
      });

      setInitialValues(extractDefaultValues(newFormData));
      setFormData(newFormData);
      setDisplayForm(true);
    }
  }, []);

  const onValuesChange = (item) => {
    if (item.industry) {
      if (typeof window !== "undefined") {
        const event = new CustomEvent(formItemVisibilityEventName, {
          detail: {
            key: "industry",
            value: item.industry,
          },
        });
        window.dispatchEvent(event);
      }
    }
  };

  return (
    <Layout hideBanner>
      <SEO title="Learn more" image={`https://spoton.com/${ogImage}`} />
      <section className="demo" style={{ marginTop: 0, paddingTop: 0 }}>
        <div className="demo__content-wrapper">
          <div className="form-wrapper">
            <LeadFormTeamwork
              formName="Lead Form"
              formId="695945d0-76a0-4cba-a1a5-2a2ab7c826e7"
              displayForm={displayForm}
              formData={formData}
              initialValues={initialValues}
              header="Get your free demo"
              subHeader="Learn how SpotOn Teamwork can help simplify your day-to-day labor management."
              onValuesChange={onValuesChange}
              formItemsToSend={[
                "firstname",
                "email",
                "phone",
                "company",
                "business_type",
                "teamwork_features",
                "product",
              ]}
              toggleVisibilityMap={[
                {
                  changeOnKey: "industry",
                  changeOnValue: "Other",
                  togglesItem: "business_type",
                },
              ]}
              useFormValueAsDataLayerEventName={
                useFormValueAsDataLayerEventName
              }
              formValueForDataLayerEventName={formValueForDataLayerEventName}
              dataLayerEventNameAppendix={dataLayerEventNameAppendix}
            />
          </div>
        </div>
        <Image imageName="teamwork.png" className="demo__heading-img" />
        <section className="trusted-numbers-wrapper">
          <TrustedNumnbers numbersArray={terminalTrustedNumbers} />
        </section>
        <PartnerLogos />
        <section style={{ marginTop: 60 }}>
          <TestmonialReviews sectionData={demoTestimonials} />
        </section>
      </section>
    </Layout>
  );
}

DemoWithProductsInterestCheck.propTypes = {
  useFormValueAsDataLayerEventName: bool,
  formValueForDataLayerEventName: string,
  dataLayerEventNameAppendix: string,
};

DemoWithProductsInterestCheck.defaultProps = {
  useFormValueAsDataLayerEventName: true,
  formValueForDataLayerEventName: "industry",
  dataLayerEventNameAppendix: "-lead",
};

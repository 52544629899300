import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import PropTypes from "prop-types";
import { GatsbyImage } from "gatsby-plugin-image";

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.com/docs/use-static-query/
 */

const Image = (props) => {
  const data = useStaticQuery(graphql`
    query HeaderImages {
      allFile(
        filter: { relativePath: {}, relativeDirectory: { eq: "global_assets" } }
      ) {
        edges {
          node {
            base
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  `);

  return (
    <>
      {data.allFile.edges.map(
        ({ node }, idx) =>
          node.base === props.imageName && (
            <GatsbyImage
              image={node.childImageSharp.gatsbyImageData}
              key={idx.toString()}
              className={props.className}
              style={props.style}
              alt={props.alt || "image"}
              objectFit={props.objectFit}
            />
          )
      )}
    </>
  );
};

Image.propTypes = {
  imageName: PropTypes.string,
  className: PropTypes.string,
  alt: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.any),
  objectFit: PropTypes.string,
};
Image.defaultProps = {
  imageName: "",
  className: "",
  alt: "",
  style: {},
  objectFit: "cover",
};

export default Image;

import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Typography } from "antd";
import Heading from "../../components-v2/Base/HeadingBuilder";
import TrustedNumbersStyles from "./TrustedNumbersStyles";

const { Title } = Typography;

const TrustedNumbers = (props) => {
  const { numbersArray, title, bottomOn } = props;

  return (
    <section className="trusted-numbers">
      {title && (
        <Title level={2} className="text-center">
          {title}
          <span className="blue_dot">.</span>
        </Title>
      )}
      <Row
        gutter={[32, 32]}
        className={`${numbersArray.length === 3 && "numbers-spacing"}`}
      >
        {numbersArray.map((num, idx) => (
          <Col
            lg={numbersArray.length === 4 ? 6 : 8}
            md={numbersArray.length === 4 ? 12 : 8}
            sm={24}
            xs={24}
            className="flex flex-col"
            key={`trusted-numbers__${idx.toString()}`}
          >
            <div
              className={`trusted-numbers__block flex flex-col h-full ${
                idx === 3 || numbersArray.length === 3
                  ? `${!bottomOn && "no-bottom"}`
                  : null
              } `}
            >
              {title ? (
                <Heading level={3} injectionType={4} className="mb-0 p-0">
                  {num.title}
                </Heading>
              ) : (
                <Heading
                  level={2}
                  injectionType={4}
                  className="mb-0 p-0 special text-[24px]"
                >
                  {num.title}
                </Heading>
              )}
              <p>{num.subtext}</p>
            </div>
          </Col>
        ))}
      </Row>
      <TrustedNumbersStyles />
    </section>
  );
};

TrustedNumbers.propTypes = {
  numbersArray: PropTypes.arrayOf(PropTypes.any),
  title: PropTypes.string,
  bottomOn: PropTypes.bool,
};
TrustedNumbers.defaultProps = {
  numbersArray: "",
  title: "",
  bottomOn: false,
};

export default TrustedNumbers;

import React, { useRef, useState, useEffect } from "react";
import { string, bool } from "prop-types";
import { Form, Button, Row, Col, Typography } from "antd";
import axios from "axios";
import { navigate } from "gatsby";
import Cookies from "js-cookie";
import { formItemVisibilityEventName } from "../../constants";
import FormItemsBuilder from "./FormItems/FormItemsBuilder";
import Image from "../image";
import { getThankYouUrl } from "../../utils/url-utils";

import chevron from "../../images/svg/chevron_left.svg";
import { LeadFormDefaultProps, LeadFormProps } from "../../props/LeadFormProps";
import { SPOTON_TEAMWORK, submitForm } from "../../utils/firebase/forms";
import { getUrlParams } from "../../../helpers";

const { Title } = Typography;

export function encode(data) {
  const formData = new FormData();

  for (const key of Object.keys(data)) {
    formData.append(key, data[key]);
  }

  return formData;
}

const productInterestValues = [
  "scheduling",
  "tip_management",
  "employee_collaboration",
  "compliance",
  "payroll",
];

const LeadFormTeamwork = (props) => {
  const {
    className,
    formName,
    leadType,
    header,
    subHeader,
    thankYouPath,
    formData,
    onValuesChange,
    toggleVisibilityMap,
    useFormValueAsDataLayerEventName,
    formValueForDataLayerEventName,
    dataLayerEventNameAppendix,
    initialValues,
    displayForm,
    formCta,
    plain,
    noHeader,
  } = props;
  const formRef = useRef(null);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(formData);

  const visibilityListener = (e) => {
    const { key, value } = e.detail;
    const toggle = toggleVisibilityMap.find((item) => item.changeOnKey === key);

    if (toggle) {
      const newData = data.map((item) => {
        if (item.name === toggle.togglesItem) {
          return { ...item, visible: toggle.changeOnValue === value };
        }

        return item;
      });
      setData(newData);
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener(formItemVisibilityEventName, visibilityListener);
    }

    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener(
          formItemVisibilityEventName,
          visibilityListener
        );
      }
    };
  });

  useEffect(() => {
    setData(formData);
  }, [formData]);

  useEffect(() => {});

  const onFinish = async (values) => {
    setLoading(true);

    const teamworkFeatures = Object.keys(values)
      .filter((key) => productInterestValues.includes(key) && values[key])
      // .map((key) => ({ [key]: values[key] || false }));
      .map((key) => key)
      .join(", ");
    const params = getUrlParams(window.location.search);
    // const address = zipCodesMap.get(values.zip);

    const response = await axios.get("/api/handler", {
      params: { email: values.email.trim().toLowerCase() },
    });

    const emailStatus = response?.data?.result?.response?.result;

    if (emailStatus !== "valid" && emailStatus !== "catchall") {
      form.setFields([
        {
          name: "email",
          errors: ["Please enter a valid email"],
        },
      ]);
      setLoading(false);
      return;
    }

    const dataToSend = {
      first_name: values.firstName,
      last_name: values.lastName,
      email: values.email.trim().toLowerCase(),
      phone: values.phone,
      company: values.company,
      // city: address.city,
      // state: address.state,
      zip: values.zip,
      line_of_business: values.industry || "Hospitality",
      business_type: values.business_type || "",
      conversion_page: window.location.pathname,
      submitted_on: new Date(),
      product_interest: teamworkFeatures || "",
      gclid: Cookies.get("gclid") || "",
      utm_source: Cookies.get("utmSource") || "organic",
      utm_medium: Cookies.get("utmMedium") || "",
      utm_campaign: Cookies.get("utmCampaign") || "",
      utm_content: Cookies.get("utmContent") || "",
      utm_term: Cookies.get("utmTerm") || "",
      heap_userId: window?.heap?.userId || "",
      msclkid: params.msclkid || "",
      campaign_id: params.campaign_id || "",
      placement: params.placement || "",
      ad_id: params.ad_id || "",
      adset_name: params.adset_name || "",
      adset_id: params.adset_id || "",
      fbclid: params.fbclid || "",
      fbc: Cookies.get("_fbc") || "",
      fbp: Cookies.get("_fbp") || "",
      rsCode: params.rsCode || "",
    };

    const result = await submitForm(dataToSend, SPOTON_TEAMWORK);

    if (result) {
      setLoading(false);
      window.sessionStorage.setItem("Name", values.firstName);
      const thankYouUrl =
        typeof window !== "undefined"
          ? getThankYouUrl(window.location.pathname)
          : null;

      if (typeof window !== `undefined` && typeof dataLayer !== `undefined`) {
        const eventName = useFormValueAsDataLayerEventName
          ? `${values[formValueForDataLayerEventName]}${dataLayerEventNameAppendix}`
          : `${leadType}${dataLayerEventNameAppendix}`;

        // eslint-disable-next-line no-undef
        dataLayer.push({ event: eventName });
      }

      // if (typeof window !== "undefined" && typeof heap !== "undefined") {
      //   // eslint-disable-next-line no-undef
      //   heap.track("Lead", {
      //     traffic_source: Cookies.get("utmSource") || "organic",
      //     conversion_path: window.location.pathname,
      //   });
      // }

      navigate(thankYouUrl || thankYouPath);
    } else {
      setLoading(false);
    }
  };

  if (!displayForm) {
    return null;
  }

  return (
    <section className={`lead-form-wrapper ${className}`} ref={formRef}>
      {!plain && (
        <Image
          imageName="mobile-demo-form-background.png"
          className="lead-form-wrapper__demo-background"
        />
      )}
      {!noHeader && (
        <Row className="header_wrapper">
          <Col xs={24}>
            <Title level={1}>
              {header}
              <span className="blue_dot">.</span>
            </Title>
          </Col>
          <Col xs={24}>
            <p>{subHeader}</p>
          </Col>
        </Row>
      )}
      <Form
        form={form}
        name={formName}
        className="lead-form"
        layout="vertical"
        initialValues={{
          remember: false,
          ...initialValues,
        }}
        onValuesChange={onValuesChange}
        onFinish={onFinish}
      >
        {/* Form Items */}
        <FormItemsBuilder data={data} />

        {/* Submit */}
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="cta-primary"
            style={{ marginTop: 20 }}
            loading={loading}
            size="small"
          >
            {formCta || "Learn more"}
            <img
              src={chevron}
              alt="chevron icon"
              style={{ margin: "5px 5px 2px 10px" }}
            />
          </Button>
        </Form.Item>
      </Form>
    </section>
  );
};

LeadFormTeamwork.propTypes = {
  ...LeadFormProps,
  className: string,
  plain: bool,
  noHeader: bool,
};
LeadFormTeamwork.defaultProps = {
  ...LeadFormDefaultProps,
  className: "",
  plain: false,
  noHeader: false,
};

export default LeadFormTeamwork;

import { arrayOf, bool, func, shape, string } from "prop-types";
import FormItemsBuilderProps from "./FormItemsBuilderProps";
import { formItemTypes } from "../constants";

export const LeadFormProps = {
  formName: string.isRequired,
  leadType: string,
  header: string.isRequired,
  subHeader: string.isRequired,
  thankYouPath: string,
  formData: arrayOf(shape(FormItemsBuilderProps)),
  onValuesChange: func,
  toggleVisibilityMap: arrayOf(
    shape({
      changeOnKey: string,
      changeOnValue: string,
      togglesItem: string,
    })
  ),
  useFormValueAsDataLayerEventName: bool,
  formValueForDataLayerEventName: string,
  dataLayerEventNameAppendix: string,
  initialValues: shape({}),
  displayForm: bool,
};

export const LeadFormDefaultProps = {
  formName: "",
  header: "",
  subHeader: "",
  leadType: "",
  thankYouPath: "/thank-you",
  formData: [
    {
      itemType: formItemTypes.INPUT,
      itemSubType: "text",
      label: "Your Name",
      name: "firstname",
      rules: [
        {
          required: true,
          message: "Please enter your name",
          min: 2,
        },
      ],
      placeholder: "e.g. John Doe",
      visible: true,
    },
  ],
  onValuesChange: () => true,
  toggleVisibilityMap: [],
  useFormValueAsDataLayerEventName: false,
  formValueForDataLayerEventName: "",
  dataLayerEventNameAppendix: "",
  initialValues: {},
  displayForm: true,
};

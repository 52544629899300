import React from "react";
import PropTypes from "prop-types";
import Image from "../image";
import Title from "../../components-v2/Base/Title";

const imageArray = [
  "thunderdome.png",
  "the-bungalow.png",
  "loma-brewing.png",
  "basecamp.png",
  "world-wrapps.png",
  "coohills_logo.png",
  "public-house-logo.png",
  "mile-high-logo.png",
];

const PartnerLogos = ({ styles, title, titleCentered, customArr }) => {
  return (
    <section
      className="logos-wrapper max-w-[1200px] mx-4 xl:mx-40 my-10"
      style={styles}
    >
      {title && (
        <Title
          level={2}
          className={`partners__title ${titleCentered && "text-center"}`}
        >
          {title}
        </Title>
      )}
      <div className="grid grid-cols-2 md:grid-cols-8">
        {!customArr &&
          imageArray.map((img, idx) => (
            <div className="max-w-[150px] w-full max-h-[100px] mx-auto">
              <Image
                className="w-full h-[100px] justify-self-center self-center	"
                imageName={img}
                key={img}
                objectFit="scale-down"
              />
            </div>
          ))}
        {customArr &&
          customArr.map((img, idx) => (
            <div className="w-full max-h-[100px] mx-auto">
              <Image
                className="w-[75px] h-[100px] lg:w-full mx-auto justify-self-center self-center	"
                imageName={img}
                key={img}
                objectFit="scale-down"
              />
            </div>
          ))}
      </div>
    </section>
  );
};

PartnerLogos.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string),
  title: PropTypes.string,
  titleCentered: PropTypes.bool,
};
PartnerLogos.defaultProps = {
  styles: {},
  title: "",
  titleCentered: false,
};

export default PartnerLogos;

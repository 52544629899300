import {
  emailValidationRegExp,
  phoneValidationRegExp,
  formItemTypes,
  zipValidationRegExp,
} from "../../constants";
import { NewBusinessTypes } from "../common-data";

const data = [
  {
    itemType: formItemTypes.INPUT,
    itemSubType: "text",
    label: "First Name",
    name: "firstName",
    rules: [
      {
        required: true,
        message: "Please enter your first name",
        min: 2,
      },
    ],
    placeholder: "Your First Name",
    visible: true,
  },
  {
    itemType: formItemTypes.INPUT,
    itemSubType: "text",
    label: "Last Name",
    name: "lastName",
    rules: [
      {
        required: true,
        message: "Please enter your last name",
        min: 2,
      },
    ],
    placeholder: "Your Last Name",
    visible: true,
  },
  {
    itemType: formItemTypes.INPUT,
    itemSubType: "email",
    label: "Email",
    name: "email",
    rules: [
      {
        required: true,
        message: "Please enter a valid email",
        pattern: emailValidationRegExp,
      },
    ],
    placeholder: "e.g. yourname@gmail.com",
    visible: true,
  },
  {
    itemType: formItemTypes.INPUT,
    itemSubType: "text",
    label: "Business Name",
    name: "company",
    rules: [
      {
        required: true,
        message: "Please enter your business name",
        min: 3,
      },
    ],
    placeholder: "e.g. MyBusiness LLC ",
    visible: true,
  },
  {
    itemType: formItemTypes.INPUT,
    itemSubType: "tel",
    label: "Phone Number",
    name: "phone",
    rules: [
      {
        required: true,
        message: "Please enter your phone",
        pattern: phoneValidationRegExp,
      },
    ],
    placeholder: "e.g. (555) 555-1243 ",
    visible: true,
  },
  // {
  //   itemType: formItemTypes.DROPDOWN,
  //   itemSubType: null,
  //   label: "Industry",
  //   name: "industry",
  //   rules: [
  //     {
  //       required: true,
  //       message: "Please enter the type of the project",
  //     },
  //   ],
  //   placeholder: "Select your industry",
  //   options: BusinessTypes,
  //   visible: true,
  // },
  // {
  //   itemType: formItemTypes.INPUT,
  //   itemSubType: "text",
  //   label: "Business Type",
  //   name: "business_type",
  //   rules: [
  //     {
  //       required: true,
  //       message: "Please tell us about your business type",
  //       min: 3,
  //     },
  //   ],
  //   placeholder: "e.g. Pet grooming",
  //   visible: false,
  // },
  // {
  //   itemType: formItemTypes.CHECKBOX,
  //   itemSubType: null,
  //   label: "I agree to receive text message updates",
  //   name: "text_messages",
  //   visible: true,
  // },
  {
    itemType: formItemTypes.INPUT,
    itemSubType: null,
    label: "Zip Code",
    name: "zip",
    rules: [
      {
        required: true,
        message: "Please enter your zip code",
        min: 4,
        pattern: zipValidationRegExp,
      },
      // () => ({
      //   validator(_, value) {
      //     const address = zipCodesMap.get(value);
      //     if (address && address.zip_code) {
      //       return Promise.resolve();
      //     }
      //     return Promise.reject("Please enter a valid zip code");
      //   },
      // }),
    ],
    placeholder: "e.g. 10001",
    visible: true,
  },
  {
    itemType: formItemTypes.DROPDOWN,
    itemSubType: null,
    label: "Industry",
    name: "industry",
    rules: [
      {
        required: true,
        message: "Please enter the type of the project",
      },
    ],
    placeholder: "Select your industry",
    options: NewBusinessTypes,
    visible: true,
  },
  {
    itemType: formItemTypes.INPUT,
    itemSubType: "text",
    label: "Business Type",
    name: "business_type",
    rules: [
      {
        required: true,
        message: "Please tell us about your business type",
        min: 3,
      },
    ],
    placeholder: "e.g. Pet grooming",
    visible: false,
  },
  {
    itemType: formItemTypes.INPUT,
    itemSubType: null,
    label: "",
    name: "teamwork_features",
    placeholder: "",
    visible: false,
  },
  {
    itemType: formItemTypes.CHECKBOX_GROUP,
    itemSubType: null,
    label: "What are you interested in? (check all that apply):",
    options: [
      {
        label: "Scheduling",
        value: "scheduling",
      },
      {
        label: "Tip Management",
        value: "tip_management",
      },
      {
        label: "Employee Collaboration",
        value: "employee_collaboration",
      },
      {
        label: "Compliance",
        value: "compliance",
      },
      {
        label: "Payroll (coming in early 2023)",
        value: "payroll",
      },
    ],
    visible: true,
  },
];

export default data;
